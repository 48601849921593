import React, { useState } from "react";

export const NavigateContext = React.createContext();
export const NavigateConsumer = NavigateContext.Consumer;

export const NavigateProvider = props => {
  const [summary, setSummary] = useState(true);
  const [history, setHistory] = useState(false);
  const [reports, setReports] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [customerId, setCustomerId] = useState("");

  const onHistory = () => {
    setHistory(true);
    setSummary(false);
    setReports(false);
    setToggle(false);
  };

  const onSummary = () => {
    setHistory(false);
    setSummary(true);
    setReports(false);
    setToggle(false);
  };

  const onReports = () => {
    setHistory(false);
    setSummary(false);
    setReports(true);
    setToggle(false);
  };

  const onToggle = () => {
    setToggle(true);
    setHistory(false);
    setSummary(false);
    setReports(false);
  };

  const offToggle = () => {
    setToggle(false);
    setHistory(true);
  };

  return (
    <NavigateContext.Provider
      value={{
        summary,
        onSummary,
        reports,
        onReports,
        history,
        onHistory,
        customerId,
        toggle,
        onToggle,
        offToggle,
        setCustomerId
      }}
    >
      {props.children}
    </NavigateContext.Provider>
  );
};
