import React, { useEffect } from "react";
import { NavigateProvider } from "./context/NavContext";
import { DataProvider } from "./context/DataContext";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { getMainDefinition } from "apollo-utilities";
import { ApolloLink, split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import Main from "./main/Main";
import "./App.css";

const httpLink = new HttpLink({
  uri: "https://home-credit-service-api.herokuapp.com/graphql"
});

const wsLink = new WebSocketLink({
  uri: `wss://home-credit-service-api.herokuapp.com/graphql`,
  options: {
    reconnect: true
  }
});

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem("token");

    if (token) {
      headers = { ...headers, "x-token": token };
    }

    return { headers };
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log("GraphQL_error", message);

      // if (message === "UNAUTHENTICATED") {
      //   signOut(client);
      // }
    });
  }

  if (networkError) {
    console.log("Network error", networkError);

    // if (networkError.statusCode === 401) {
    //   signOut(client);
    // }
  }
});

const link = ApolloLink.from([authLink, errorLink, terminatingLink]);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache
});
const App = () => {
  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    // console.log(query); //"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      // console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  useEffect(() => {
    var query = window.location.search.substring(1);
    console.log("Query: ", query); //"app=article&act=news_content&
    localStorage.setItem("fb_id", getQueryVariable("fb_id"));
  }, []);

  useEffect(() => {
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "Messenger");
  }, []);

  useEffect(() => {
    window.extAsyncInit = function() {
      console.log("Done loading");
    };
  }, []);
  return (
    <ApolloProvider client={client}>
      <NavigateProvider>
        <DataProvider>
          <div className="font-sans leading-normal tracking-normal">
            <Main />
          </div>
        </DataProvider>
      </NavigateProvider>
    </ApolloProvider>
  );
};

export default App;
