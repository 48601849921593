import React, { useContext, useEffect, useState } from "react";
import RechartData from "../../chart/Pie";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { useQuery } from "@apollo/react-hooks";
import {
  QUERY_EXPENSES,
  USER_EXPENSE,
  USER_INCOME,
  TOTAL_DISTICT_EXPENSE
} from "../../graphql/query";
import { DataContext } from "../../context/DataContext";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 }
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;

export default function Summary() {
  // const [expense, setExpense] = useState({});
  // const [income, setIncome] = useState({});

  const {
    expense,
    setExpense,
    income,
    setIncome,
    spendings,
    setSpendings
  } = useContext(DataContext);

  function TotalDistinct() {
    const { loading, error, data } = useQuery(TOTAL_DISTICT_EXPENSE, {
      variables: {
        fb_id: localStorage.getItem("fb_id")
      }
    });
    if (loading) return <p>Loading...</p>;
    setSpendings(data.getTotalDistinctExpenses);
    console.log("Distinct: ", data);

    if (error)
      return error.graphQLErrors.map(({ message, i }) => <p>{message}</p>);
  }

  function UserExpense() {
    const { loading, error, data } = useQuery(USER_EXPENSE, {
      variables: {
        fb_id: localStorage.getItem("fb_id")
      }
    });
    if (loading) return <p>Loading...</p>;

    setExpense(data.getExpense);

    console.log("Expense: ", data);

    if (error)
      return error.graphQLErrors.map(({ message, i }) => <p>{message}</p>);
  }
  function UserIncome() {
    const { loading, error, data } = useQuery(USER_INCOME, {
      variables: {
        fb_id: localStorage.getItem("fb_id")
      }
    });
    if (loading) return <p>Loading...</p>;

    setIncome(data.getIncome);
    console.log("Income: ", data);

    if (error)
      return error.graphQLErrors.map(({ message, i }) => <p>{message}</p>);
  }

  const totalExpense = expense.map(expense => expense.amount);
  const totalExpenses = totalExpense.reduce((a, b) => a + b, 0);

  const totalIncome = income.map(income => income.amount);
  const savings = totalIncome.reduce((a, b) => a + b, 0);

  const overallTotal = savings - totalExpenses;

  const credit = 100 - (totalExpenses / savings) * 100;
  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 xl:w-1/3 p-3 ">
          <h4 className="font-semibold uppercase text-gray-500">Hello,</h4>
          <h4 className="font-bold uppercase text-gray-600 mb-3">Pawer Mac!</h4>
          {UserExpense()}
          {TotalDistinct()}
          {UserIncome()}
          <div className="bg-white rounded-lg shadow-lg p-8 text-center ">
            <div className="flex flex-row items-center">
              <div className="flex-1  text-center -mt-20">
                <h4 className="font-semibold uppercase text-gray-600">
                  Total Balance
                </h4>
                <h4 className="font-bold text-3xl">₱ {overallTotal} </h4>
                <div className="flex flex-row">
                  <span class="inline-block bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700 ">
                    <span className="">CREDITSCORE:</span>
                    <span className="text-gray-400"></span>
                    {credit.toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="flex-auto text-right">
                <PieChart
                  width={400}
                  height={200}
                  // onMouseEnter={this.onPieEnter}
                >
                  <Pie
                    data={data}
                    cx={110}
                    cy={60}
                    innerRadius={45}
                    outerRadius={60}
                    fill="#8884d8"
                    paddingAngle={2}
                  >
                    {data.map((entry, index) => (
                      <Cell fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            </div>

            <div class="flex mb-4 -mt-10">
              <div class="w-1/2  h-12">
                <div className="flex-1 text-center">
                  <div className="flex-1  text-center">
                    <h4 className="font-bold text-2xl text-gray-700">
                      <span className="text-green-500">
                        <i className="fas fa-caret-up"></i>
                      </span>{" "}
                      ₱ {savings}
                    </h4>
                    <h5 className="font-semibold uppercase text-gray-500">
                      Total Savings
                    </h5>
                  </div>
                </div>
              </div>
              <div class="w-1/2  h-12 ">
                <div className="flex-1 ">
                  <div className="flex-1  text-center">
                    <h4 className="font-bold text-2xl text-gray-700">
                      <span className="text-red-500">
                        <i className="fas fa-caret-down"></i>
                      </span>{" "}
                      ₱ {totalExpenses}
                    </h4>
                    <h4 className="font-semibold uppercase text-gray-500">
                      Spent today
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 p-3">
          {/* <!--Metric Card--> */}
          <div className="bg-green-100 border-b-4 border-green-600 rounded-lg shadow-lg p-5">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-green-600">
                  <i className="fa fa-wallet fa-2x fa-inverse"></i>
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h5 className="font-bold uppercase text-gray-600">
                  Total Expense Transactions
                </h5>
                <h3 className="font-bold text-3xl">
                  {expense.length}
                  <span className="text-green-500">
                    <i className="fas fa-caret-up"></i>
                  </span>
                </h3>
              </div>
            </div>
          </div>
          {/* <!--/Metric Card--> */}
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 p-3">
          {/* <!--Metric Card--> */}
          <div className="bg-orange-100 border-b-4 border-orange-500 rounded-lg shadow-lg p-5">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-orange-600">
                  <i className="fas fa-users fa-2x fa-inverse"></i>
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h5 className="font-bold uppercase text-gray-600">
                  Total Income Transactions
                </h5>
                <h3 className="font-bold text-3xl">
                  {expense.length}
                  <span className="text-orange-500">
                    <i className="fas fa-exchange-alt"></i>
                  </span>
                </h3>
              </div>
            </div>
          </div>
          {/* <!--/Metric Card--> */}
        </div>
      </div>
    </div>
  );
}
