import gql from "graphql-tag";

export const QUERY_EXPENSES = gql`
  query {
    getExpenses {
      id
      fb_id
      category
      amount
      repeating
      every
      next_reminder
      transactionDate
    }
  }
`;

export const USER_EXPENSE = gql`
  query USER_EXPENSE($fb_id: ID) {
    getExpense(fb_id: $fb_id) {
      id
      fb_id
      category
      amount
      repeating
      every
      next_reminder
      transactionDate
    }
  }
`;

export const TOTAL_DISTICT_EXPENSE = gql`
  query USER_EXPENSE($fb_id: ID) {
    getTotalDistinctExpenses(fb_id: $fb_id) {
      name
      value
    }
  }
`;

export const TOTAL_DISTICT_INCOME = gql`
  query USER_EXPENSE($fb_id: ID) {
    getTotalDistinctIncome(fb_id: $fb_id) {
      name
      value
    }
  }
`;

export const USER_INCOME = gql`
  query USER_INCOME($fb_id: ID) {
    getIncome(fb_id: $fb_id) {
      id
      fb_id
      category
      amount
      repeating
      every
      transactionDate
    }
  }
`;
