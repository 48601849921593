import React from "react";
import RechartData from "../../chart/Pie";
import RadialChart from "../../chart/RadialChart";
import { Pie, yuan } from "ant-design-pro/lib/Charts";
import "ant-design-pro/dist/ant-design-pro.css";
import { TOTAL_DISTICT_INCOME } from "../../graphql/query";
import { useQuery } from "@apollo/react-hooks";

export default function Reports() {
  const { loading, error, data } = useQuery(TOTAL_DISTICT_INCOME, {
    variables: {
      fb_id: localStorage.getItem("fb_id")
    }
  });
  if (loading) return <p>Loading...</p>;

  if (error)
    return error.graphQLErrors.map(({ message, i }) => <p>{message}</p>);

  const computedDate = data.getTotalDistinctIncome.map(data => {
    return {
      x: data.name,
      y: data.value
    };
  });

  return (
    <div className="flex flex-row flex-wrap flex-grow mt-2 mb-2">
      <div className="w-full md:w-1/2 xl:w-1/3 p-3 mb-4">
        <div className="bg-white border-transparent rounded-lg shadow-lg">
          <div className="bg-gray-400 border-b-2 border-gray-500 rounded-tl-lg rounded-tr-lg p-2">
            <h5 className="font-bold uppercase text-gray-600">Income Graph</h5>
          </div>
          <div className="p-5">
            <Pie
              hasLegend
              title="Demographics"
              subTitle="Savings"
              total={() => (
                <span
                  dangerouslySetInnerHTML={{
                    __html: computedDate.reduce((pre, now) => now.y + pre, 0)
                  }}
                />
              )}
              data={computedDate}
              valueFormat={val => (
                <span dangerouslySetInnerHTML={{ __html: val }} />
              )}
              height={294}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
