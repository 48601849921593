import React from "react";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import RechartData from "../../chart/Pie";
import { USER_INCOME } from "../../graphql/query";
import { useQuery } from "@apollo/react-hooks";

export default function IncomeHistory() {
  const { loading, error, data } = useQuery(USER_INCOME, {
    variables: {
      fb_id: localStorage.getItem("fb_id")
    }
  });
  if (loading) return <p>Loading...</p>;
  console.log("Datas: ", data);
  if (error)
    return error.graphQLErrors.map(({ message, i }) => <p>{message}</p>);
  return (
    <div className="pt-6 mt-5">
      <div className="w-full md:w-1/2 xl:w-1/3 p-3 ">
        {loading && (
          <h4 className="font-bold uppercase text-gray-600 mb-3">
            Loading data ...
          </h4>
        )}
        {data.getIncome.map(data => {
          return (
            <div
              className="bg-white rounded-lg shadow-lg p-8 text-center mb-5"
              key={data.id}
            >
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded p-5 bg-green-600">
                    {data.category === "salary" && (
                      <i className="fa fa-wallet fa-2x fa-inverse"></i>
                    )}
                    {data.category === "refund" && (
                      <i className="fa fa-plane fa-2x fa-inverse"></i>
                    )}

                    {data.category === "reimbursement" && (
                      <i className="fa fa-credit-card fa-2x fa-inverse"></i>
                    )}

                    {data.category === "lending" && (
                      <i className="fa fa-wallet fa-2x fa-inverse"></i>
                    )}
                    {data.category === "gift" && (
                      <i className="fa fa-wallet fa-2x fa-inverse"></i>
                    )}
                  </div>
                </div>
                <div className="text-left">
                  <p className="font-bold text-sm uppercase text-gray-600">
                    {data.category}
                  </p>
                  <p className="font-bold text-xs uppercase text-gray-400">
                    {new Date(data.transactionDate).toLocaleDateString()}
                    {/* {data.transactionDate && data.transactionDate}
                    {data.transactionDate &&
                      format(data.transactionDate, "MM/dd/yyyy")} */}
                  </p>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h3 className="font-bold text-2xl">
                    <span className="text-green-500">
                      <i className="fas fa-plus"></i>
                    </span>{" "}
                    ₱{data.amount}
                  </h3>
                </div>
              </div>
            </div>
          );
        })}

        {/* <div className="bg-white rounded-lg shadow-lg p-8 text-center">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded p-5 bg-orange-600">
                <i className="fas fa-plane fa-2x fa-inverse"></i>
              </div>
            </div>
            <div className="text-left">
              <p className="font-bold text-sm uppercase text-gray-600">
                Masarap Pagkain
              </p>
              <p className="font-bold text-xs uppercase text-gray-400">
                November 24, 2019
              </p>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h3 className="font-bold text-2xl">
                <span className="text-green-500">
                  <i className="fas fa-plus"></i>
                </span>{" "}
                ₱3,249
              </h3>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
