import React, { useContext } from "react";
import Header from "../header/Header";
import Navigation from "../navigation/Navigation";
import Body from "../body/Body";
import { NavigateContext } from "../context/NavContext";

export default function Main() {
  const { summary, reports, history } = useContext(NavigateContext);

  return (
    <div>
      <Header />
      <div className="flex flex-col md:flex-row">
        <Navigation />
        <Body />
      </div>
    </div>
  );
}
