import React, { useContext } from "react";
import { NavigateContext } from "../context/NavContext";

export default function Navigation() {
  const {
    onSummary,
    onReports,
    onHistory,
    summary,
    reports,
    history
  } = useContext(NavigateContext);
  return (
    <div>
      <div className="bg-gray-900 shadow-lg h-16 fixed bottom-0 mt-12 md:relative md:h-screen z-10 w-full md:w-48">
        <div className="md:mt-12 md:w-48 md:fixed md:left-0 md:top-0 content-center md:content-start text-left justify-between">
          <ul className="list-reset flex flex-row md:flex-col py-0 md:py-3 px-1 md:px-2 text-center md:text-left">
            <li className="mr-3 flex-1">
              <a
                href="#"
                className={
                  summary
                    ? "block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-pink-500 border-pink-500"
                    : "block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-pink-500 "
                }
                onClick={() => onSummary()}
              >
                <i
                  className={
                    summary
                      ? "fas fa-tasks pr-0 md:pr-3 text-white"
                      : "fas fa-tasks pr-0 md:pr-3 text-gray-600"
                  }
                ></i>
                <span
                  className={
                    summary
                      ? "pb-1 md:pb-0 text-xs md:text-base text-white md:text-gray-400 block md:inline-block"
                      : "pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block"
                  }
                >
                  Summary
                </span>
              </a>
            </li>
            <li className="mr-3 flex-1">
              <a
                href="#"
                className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-purple-500"
                onClick={() => onHistory()}
              >
                <i
                  className={
                    history
                      ? "fa fa-envelope pr-0 md:pr-3 text-white"
                      : "fa fa-envelope pr-0 md:pr-3 text-gray-600"
                  }
                ></i>
                <span
                  className={
                    history
                      ? "pb-1 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block"
                      : "pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-white block md:inline-block"
                  }
                >
                  History
                </span>
              </a>
            </li>
            <li className="mr-3 flex-1">
              <a
                href="#"
                className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-red-500"
                onClick={() => onReports()}
              >
                <i
                  className={
                    reports
                      ? "fas fa-chart-area pr-0 md:pr-3 text-white"
                      : "fas fa-chart-area pr-0 md:pr-3 text-gray-600"
                  }
                />
                <span
                  className={
                    reports
                      ? "pb-1 md:pb-0 text-xs md:text-base text-white md:text-gray-600 block md:inline-block"
                      : "pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-600 block md:inline-block"
                  }
                >
                  Reports
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
