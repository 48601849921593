import React, { useState } from "react";

export const DataContext = React.createContext();
export const DataConsumer = DataContext.Consumer;

export const DataProvider = props => {
  const [expense, setExpense] = useState([]);
  const [income, setIncome] = useState([]);
  const [spendings, setSpendings] = useState([]);

  return (
    <DataContext.Provider
      value={{
        expense,
        setExpense,
        income,
        setIncome,
        spendings,
        setSpendings
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
