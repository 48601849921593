import React, { useState } from "react";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import RechartData from "../../chart/Pie";
import { QUERY_EXPENSES, USER_EXPENSE } from "../../graphql/query";
import { useQuery } from "@apollo/react-hooks";
import Expense from "./Expense";
import Incomes from "./Income";

export default function History() {
  const [expense, setExpense] = useState(true);
  const [income, setIncome] = useState(false);

  const onExpense = () => {
    setIncome(false);
    setExpense(true);
  };
  const onIncome = () => {
    setIncome(true);
    setExpense(false);
  };

  return (
    <div className="pt-6 mt-5">
      <div className="w-full md:w-1/2 xl:w-1/3 p-3 ">
        <h4 className="font-bold uppercase text-gray-600 mb-3">
          Latest Transactions
        </h4>
        <div className="flex mb-4 text-center">
          <div className="w-1/2  h-12">
            <button
              className={
                !expense
                  ? "bg-gray-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              }
              onClick={() => onExpense()}
            >
              Expense
            </button>
          </div>
          <div className="w-1/2  h-12">
            <button
              className={
                !income
                  ? "bg-gray-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              }
              onClick={() => onIncome()}
            >
              Income
            </button>
          </div>
        </div>
        {expense && <Expense />}
        {income && <Incomes />}
      </div>
    </div>
  );
}
