import React, { useContext } from "react";
import { NavigateContext } from "../context/NavContext";
import Summary from "../page/summary/Summary";
import Reports from "../page/reports/Reports";
import History from "../page/history/History";

export default function Body() {
  const { summary, reports, history } = useContext(NavigateContext);
  return (
    <div className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5">
      {summary && <Summary />}
      {reports && <Reports />}
      {history && <History />}
    </div>
  );
}
