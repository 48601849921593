import React from "react";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import RechartData from "../../chart/Pie";
import { QUERY_EXPENSES, USER_EXPENSE } from "../../graphql/query";
import { useQuery } from "@apollo/react-hooks";

export default function History() {
  const { loading, error, data } = useQuery(USER_EXPENSE, {
    variables: {
      fb_id: localStorage.getItem("fb_id")
    }
  });
  if (loading) return <p>Loading...</p>;
  console.log("Datas: ", data);
  if (error)
    return error.graphQLErrors.map(({ message, i }) => <p>{message}</p>);
  return (
    <div>
      {loading && (
        <h4 className="font-bold uppercase text-gray-600 mb-3">
          Loading data ...
        </h4>
      )}
      {data.getExpense.map(data => {
        return (
          <div
            className="bg-white rounded-lg shadow-lg p-8 text-center mb-5"
            key={data.id}
          >
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded p-5 bg-green-600">
                  {data.category === "food" && (
                    <i className="fa fa-wallet fa-2x fa-inverse"></i>
                  )}
                  {data.category === "transportation" && (
                    <i className="fa fa-plane fa-2x fa-inverse"></i>
                  )}

                  {data.category === "load" && (
                    <i className="fa fa-credit-card fa-2x fa-inverse"></i>
                  )}

                  {data.category === "apartment" && (
                    <i className="fa fa-wallet fa-2x fa-inverse"></i>
                  )}
                  {data.category === "electricity" && (
                    <i className="fa fa-wallet fa-2x fa-inverse"></i>
                  )}
                  {data.category === "internet" && (
                    <i className="fa fa-wifi fa-2x fa-inverse"></i>
                  )}
                  {data.category === "shopping" && (
                    <i className="fa fa-shopping-bag fa-2x fa-inverse"></i>
                  )}
                  {data.category === "general" && (
                    <i className="fa fa-wallet fa-2x fa-inverse"></i>
                  )}
                </div>
              </div>
              <div className="text-left">
                <p className="font-bold text-sm uppercase text-gray-600">
                  {data.category}
                </p>
                <p className="font-bold text-xs uppercase text-gray-400">
                  {new Date(data.transactionDate).toLocaleDateString()}
                  {/* {data.transactionDate && data.transactionDate}
                    {data.transactionDate &&
                      format(data.transactionDate, "MM/dd/yyyy")} */}
                </p>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h3 className="font-bold text-2xl">
                  <span className="text-red-500">
                    <i className="fas fa-minus"></i>
                  </span>{" "}
                  ₱{data.amount}
                </h3>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
