import React, { useContext } from "react";
import { NavigateContext } from "../context/NavContext";

const Navigation = () => {
  const { summary, reports, history } = useContext(NavigateContext);
  return (
    <nav className="bg-gray-900 pt-0 md:pt-0 pb-0 px-0 mt-0 h-auto fixed w-full z-20 top-0 text-center">
      <div className="bg-blue-800 p-2 shadow text-xl text-white ">
        {summary && <h3 className="font-bold pl-2">Summary</h3>}
        {history && <h3 className="font-bold pl-2">History</h3>}
        {reports && <h3 className="font-bold pl-2">Report</h3>}
      </div>
      {!summary && (
        <div className="flex flex-wrap items-center">
          <div className="flex w-full pt-2 content-center justify-between md:w-1/3 md:justify-end">
            <ul className="list-reset flex justify-between flex-1 md:flex-none items-center">
              <li className="flex-1 md:flex-none md:mr-3">
                <a
                  className="inline-block py-2 px-4 text-white no-underline"
                  href="#"
                >
                  Weekly
                </a>
              </li>
              <li className="flex-1 md:flex-none md:mr-3">
                <a
                  className="inline-block py-2 px-4 text-white no-underline"
                  href="#"
                >
                  Monthly
                </a>
              </li>
              <li className="flex-1 md:flex-none md:mr-3">
                <a
                  className="inline-block py-2 px-4 text-white no-underline"
                  href="#"
                >
                  Yearly
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
